import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCog, faSignOutAlt, faBars, faShoppingBasket, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../C-PRODUCT LAYOUT/shopingcardpro.css';
import { Link, useLocation } from "react-router-dom";
import tri from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/Untitled-3.png';
import trilogo from '../IMAGES-VIDEOS/B-IMG-PRODUCT-LAYOUT/Nystai-icons & resize images/nys logo  (2).png';
import { categories, products } from './datass';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from 'react-tooltip';
import Sample1 from "./HYBRIDAI-NVR";
import Camera from "./CAMERA";
import Alarmautomation from "./ALARM & AUTOMATION";
import SENSORS from "./SENSORS";
import Accesscontrol from "./ACCSESS.js";
import Indutrial from "./INDUSTRIALAI-NVR";
import SMARTSWITCH from "./SMART SWITCH";
import DIGITALCLASS from "./DIGITALCLASS.js";
import AIVMS from "./AI-VMS.js";
import WATER from "./WATER.js";
import FIRESAFETY from "./FIRESAFETY.js";
import ELECTRICFENCE from "./ELECTRIC FENCE";
import ACCESSORIES from "./ACCESSORIES.js";


const AdminHub = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isSidebarHidden, setIsSidebarHidden] = useState(window.innerWidth < 768);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarHidden(true);
      } else {
        setIsSidebarHidden(false);
      }
      if (window.innerWidth > 576) {
        setIsSearchFormVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialCategory = searchParams.get('category');
  const initialSubcategory = searchParams.get('subcategory');

  useEffect(() => {
    if (initialCategory) {
      setSelectedCategory(initialCategory);
    }
    if (initialSubcategory) {
      setSelectedSubcategory(initialSubcategory);
    }
  }, [initialCategory, initialSubcategory]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedCategory]);

  const renderCategoryComponent = () => {
    switch (parseInt(selectedCategory)) {
      case 1:
        return <Sample1 />;
      case 2:
        return <Indutrial />;
      case 3:
        return <Alarmautomation />;
      case 4:
        return <Camera />;
      case 5:
        return <SENSORS />;
      case 6:
        return <SMARTSWITCH />;
      case 7:
        return <Accesscontrol />;
      case 8:
        return <DIGITALCLASS />;
      case 9:
        return <AIVMS />;
      case 10:
        return <WATER />;
      case 11:
        return <ELECTRICFENCE />;
      case 12:
        return <FIRESAFETY />;
      case 13:
        return <ACCESSORIES />;
      default:
        return <div>Please select a category</div>;
    }
  };

  return (
    <>

      <body className="pro-body">

        <section id="sidebar" className={isSidebarHidden ? 'hide' : ''}>
          <Link to="/nystai-home">
            <a className="brand mt-3">
              <img src={tri} className="me-2" alt="Brand Logo" style={{ width: "fit-content", height: "35px" }} />
              <img src={trilogo} alt="nys-logo" className="img-fluid nys-logo-side" style={{ width: "fit-content", height: "30px" }} />
            </a>
          </Link>

          <div className="side-menu top">
            <li onClick={toggleSidebar}>
              <a >
                <FontAwesomeIcon icon={faBars} className=" dash-icon" />
                <span className="text" style={{ fontWeight: "bolder" }}>Product Category</span>
              </a>
            </li>
          </div>

          <div className="side-menu top">
            {categories.map((category) => (
              <li
                key={category.id}
                className={` ${parseInt(selectedCategory) === category.id ? 'active' : ''}`}
                onClick={() => {
                  setSelectedCategory(category.id);
                  setSelectedSubcategory(null);
                }}
              >
                <a>
                  <FontAwesomeIcon icon={category.icons} className="dash-icon" />
                  <span className="text">{category.name}</span>

                </a>
              </li>
            ))}
          </div>

          <div className="side-menu">
            <li>
              <a className="logout">
                <FontAwesomeIcon icon={faCog} className="dash-icon" />
                <span className="text">Store</span>
              </a>
            </li>
            <li>
              <a className="logout">
                <FontAwesomeIcon icon={faSignOutAlt} className="dash-icon" />
                <span className="text">Build your system</span>
              </a>
            </li>
          </div>
        </section>


        <section id="content">

          <nav class="navbar navbar-expand-lg  sticky-top ">

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse " id="navbarSupportedContent">
              <ul class="navbar-nav m-auto">
                <li class="nav-item active">
                  <a className="nav-link "><Link to="/nystai-product">PRODUCTS</Link></a>
                </li>
                <li class="nav-item">
                  <a className="nav-link"><Link to="/nystai-solution">INTEGRATED SOLUTIONS</Link></a>
                </li>
                <li class="nav-item">
                  <a className="nav-link">PROTECT PLAN</a>
                </li>
                <li class="nav-item">
                  <a className="nav-link">SERVICE</a>
                </li>
                <li class="nav-item">
                  <a className="nav-link"><Link to="/nystai-support" >SUPPORT</Link></a>
                </li>
              </ul>

              <ul class="navbar-nav mr-auto ">
                <li class="nav-item me-2">
                  <a className="nav-link">
                    <input type="checkbox" id="switch-mode" hidden onChange={(e) => setIsDarkMode(e.target.checked)} />
                    <label htmlFor="switch-mode" className="switch-mode"></label>
                  </a>
                </li>
                <li class="nav-item me-4">
                  <a className="nav-link">  <FontAwesomeIcon icon={faWhatsapp} className="icon-pro-font" /><Tooltip id="my-tooltip" place="bottom" className="tooltip" /></a>
                </li>
                <li class="nav-item me-4">
                  <a className="nav-link">  <FontAwesomeIcon icon={faSearch} className="icon-pro-font" /><Tooltip id="my-tooltip" place="bottom" className="tooltip" /></a>
                </li>
                <li class="nav-item">
                  <a className="nav-link"><FontAwesomeIcon icon={faShoppingBasket} className="icon-pro-font " /></a>
                </li>
              </ul>
            </div>
          </nav>

          <main>

            {/* <div className="head-title ">
              <div className="left">
                <ul className="breadcrumb">
                  <li>
                    <a className="activeproname"> {products.name}</a>
                  </li>
                  <li><FontAwesomeIcon icon={faChevronRight} className=" dash-icon" /></li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading1">Overview</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading2">Performance and benefits</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading3">Application scenarios</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading4">Product categories</a>
                  </li>
                  <li className="nav-item nav-sec-solu-nys-items">
                    <a className="nav-link" href="#scrollspyHeading5">Build your Idea</a>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="container-fluid">
              {renderCategoryComponent()}
            </div>

          </main>

        </section>

      </body>
    </>
  );
};

export default AdminHub;
