import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { BrowserRouter as Router, Routes, Route, HashRouter } from 'react-router-dom';
import AdminHub from './NYSTAI-WEBSITE/C-PRODUCT LAYOUT/productmain';
import Layout from './NYSTAI-WEBSITE/A-LAYOUT/nav-layout-nystai';
import Nystaihome from './NYSTAI-WEBSITE/B-HOME LAYOUT/nystai-home';
import Solutionnystai from './NYSTAI-WEBSITE/D-SOLUTION LAYOUT/solution';
import Support from './NYSTAI-WEBSITE/G-SUPPORT LAYOUT/support';
import Faqs from './NYSTAI-WEBSITE/G-SUPPORT LAYOUT/faqs';
import Privacypolicy from './NYSTAI-WEBSITE/A-LAYOUT/privacypolicy';
import Warrenty from './NYSTAI-WEBSITE/A-LAYOUT/warrenty';
import Termspolicys from './NYSTAI-WEBSITE/A-LAYOUT/terms';

function App() {
  return (

    <HashRouter>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<Nystaihome  />} />
          <Route path="nystai-home" element={<Nystaihome />} />
          <Route path="nystai-solution" element={<Solutionnystai />} />
          {/* <Route path="nystai-service" element={<Servicesnystai />} /> */}
          {/* <Route path="nystai-protplan" element={<Demo2020 />} /> */}
          <Route path="nystai-support" element={<Support />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="nystai-privacy-policy" element={<Privacypolicy />} />
          <Route path="nystai-warrenty" element={<Warrenty />} />
          <Route path="nystai-terms-condition" element={<Termspolicys />} />
        </Route>
        <Route path="nystai-product" element={<AdminHub />} />
      </Routes>
    </HashRouter>

  );
}

export default App;