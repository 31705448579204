import React from "react";
import './sample.css'
import { useState, useEffect } from "react";

export default function Tabsliderpro() {

    const [currentBanner, setCurrentBanner] = useState(1);

    useEffect(() => {
        const bannerTimer = setInterval(() => {
            setCurrentBanner(prevBanner => (prevBanner % 5) + 1);
        }, 10000);

        return () => {
            clearInterval(bannerTimer);
        };
    }, []);

    useEffect(() => {
        const nextBanner = document.getElementById(`banner${currentBanner}`);
        if (nextBanner) nextBanner.click();
    }, [currentBanner]);


    return (
        <>

            <section className='container hover-tab-body-nystai mt-4 mb-4'>
                <section id="section-1">
                    <div className="content-slider">
                        <input type="radio" id="banner1" className="sec-1-input" name="banner" defaultChecked />
                        <input type="radio" id="banner2" className="sec-1-input" name="banner" />
                        <input type="radio" id="banner3" className="sec-1-input" name="banner" />
                        <input type="radio" id="banner4" className="sec-1-input" name="banner" />
                        <input type="radio" id="banner5" className="sec-1-input" name="banner" />

                        <div class="slider">

                            <div id="top-banner-1" class="banner" >
                                <div class="banner-inner-wrapper">
                                    <h2>NystAI  exclusive Smart Deter technology</h2>
                                    <p>    NystAI security systems don’t just record crime, they help to prevent it.<br />
                                        Using light and sound to scare away lurkers before they become burglars</p>
                                </div>
                            </div>
                            <div id="top-banner-2" class="banner">
                                <div class="banner-inner-wrapper">
                                    <h2>NystAI  exclusive Smart Deter technology</h2>
                                    <p>    NystAI security systems don’t just record crime, they help to prevent it.<br />
                                        Using light and sound to scare away lurkers before they become burglars</p>
                                </div>
                            </div>
                            <div id="top-banner-3" class="banner">
                                <div class="banner-inner-wrapper">
                                    <h2>PREMIER   ALARM SYSTEM</h2>
                                    <p>
                                        With outdoor security cameras,
                                        Intruder Detection sensors that detect lurkers and scare them away,<br /> a doorbell camera, vibration & Break
                                        Sensors that detects and protects, smart locks, lighting, and security sensors<br />—your home is always protected.
                                    </p>
                                </div>
                            </div>
                            <div id="top-banner-4" class="banner">
                                <div class="banner-inner-wrapper">
                                    <h2> ALARM LINKAGE VIDEO SYSTEM</h2>
                                    <p>
                                        If you're looking for the next level in home protection, the Premier Home Security System is the perfect match for you. <br />Have more control over your home's safety with the Premier package, which adds the Intruder Detection, Electric Fence,<br /> Touchscreen Control Panel and your choice of doorbell camera.</p>
                                </div>
                            </div>
                            <div id="top-banner-5" class="banner">
                                <div class="banner-inner-wrapper">
                                    <h2> Control your entire system from a single app</h2>
                                    <p>
                                        With the NystAI app, you can turn on the lights,unlock the door, control the temperature, and see<br /> what’s happening on your front porch, even if you’re hundreds of miles away.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className="controls mb-1">
                                <label htmlFor="banner1" ><span className="progressbar"><span className="progressbar-fill"></span></span> <p className="spantext" >
                                    NYSTAI-IoT </p></label>
                                <label htmlFor="banner2" ><span className="progressbar"><span className="progressbar-fill"></span></span><p className="spantext" >  PREVENTION</p></label>
                                <label htmlFor="banner3" ><span className="progressbar"><span className="progressbar-fill"></span></span><p className="spantext" > ALARM</p></label>
                                <label htmlFor="banner4" ><span className="progressbar"><span className="progressbar-fill"></span></span><p className="spantext" > VIDEO</p></label>
                                <label htmlFor="banner5" ><span className="progressbar"><span className="progressbar-fill"></span></span> <p className="spantext" >PLATFORM </p></label>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}